import loadable from "@loadable/component";
import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import styled from "styled-components";
import { graphql } from "gatsby";

import { Footer, MetaComponent, Navbar } from "../components";
import { Button } from "../components/core/Button";
import { Section, SectionWrapper, Title } from "../components/core/commonExports";
import { OnMobile, OnDesktop } from "../components/DesktopMobileComps";
import { LeadFormProvider } from "../context/LeadFormContext";
import { useMixpanel } from "../context/MixpanelContext";

import SafeguardYourBusiness from "../components/campaigns/fbsft1/SafeguardYourBusiness";
import WhySafetyInsurance from "../components/campaigns/fbsft1/WhySafetyInsurance";
import FactsSection from "../components/campaigns/glp6/FactsSection";
import WhyChooseVerak from "../components/campaigns/glp6/WhyChooseVerak";
import FooterSection from "../components/campaigns/fbsft1/FooterSection";
import FAQs from "../components/campaigns/fbsft1/FAQs";
import SafetyPlanCamera from "../components/safety-plan/SafetyPlanCamera";

const LeadForm = loadable(() => import("../components/LeadForm"));

const quoteCtaProps = {
  label: "Get Plans",
  uppercaseLabel: false
}

const SafetyPlan = () => {
  const mixpanel = useMixpanel()
  const pageName = "Safety Plan LP"
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (e, ctaNumber ="", ctaName = "Get Plans") => {
    let eventName = `Clicked '${ctaName}' -`
    if (ctaNumber) {
      eventName += ` ${ctaNumber}`
    }
    eventName += ` (${pageName})`
    mixpanel.track(eventName);
    handleQuoteModalOpen();
  };

  const heroSectionCta = (
    <Button 
      type="flatdesign" 
      style={{padding: "12px 20px", marginTop: "22px"}} 
      mobileStyles={{margin: "auto"}} 
      label={quoteCtaProps.label}
      uppercaseLabel={quoteCtaProps.uppercaseLabel} 
      onClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)}
    />
  )

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm newTheme formType="safety-plan" quoteCtaProps={quoteCtaProps} onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent 
        title="Get complete protection for your business today!" 
        description="Give your business 360° protection with our Safety Plan!" 
        image="mlp-4"
      />
      <Navbar active="products" />
      <Section background="#FFF7F1" style={{marginTop: '4rem', position: "relative"}}>
        <SectionWrapper>
          <HeroSectionContainer>
            <HeroImgContainer>
              <StaticImage 
                src="../assets/images/campaigns/fbsft2-hero.webp"
                alt="Give your business 360° protection with our Safety Plan!"
                quality={80}
                width={592}
                height={351}
                loading="eager"
                placeholder="none"
              />
            </HeroImgContainer>
            <div style={{maxWidth: "600px"}}>
              <Title fontSize="42px" mfontSize="22px" lineHeight="60px" mlineHeight="32px" fontWeight="700" style={{textAlign: "left"}}>
                Give your business 360° protection with our Safety Plan!
              </Title>
              <OnDesktop>
                {heroSectionCta}
              </OnDesktop>
            </div>
          </HeroSectionContainer>
          <OnMobile style={{margin: "auto"}}> 
            {heroSectionCta}
          </OnMobile>
        </SectionWrapper>
      </Section>
      <SafeGuardBusinessContainer>
        <SafeguardYourBusiness 
          iconClassName="primary-icons" 
          safetyPlan
          showGetQuote={true}  
          handleGetQuoteClick={(e, ctaNumber=2) => handleGetQuoteClick(e, ctaNumber)}
          quoteCtaProps={{label: "Get Plans"}}
          mobileStyles={{background: "#fff"}}
          mobileItemCardBg="#fff"
        />
      </SafeGuardBusinessContainer>
      <WhySafetyInsurance 
        mobileStyles={{background: "#fff"}}
        desktopStyles={{background: "#fff"}}
        mobileItemCardBg="#fff"
      />
      <SafetyPlanCamera />
      <FactsSection 
        title="Why Trust Verak?" 
        desktopStyles={{background: "#FFF7F1"}}
      />
      <WhyChooseVerak 
        scrollableCards
        safetyPlan 
        hideQuoteCTA 
        title="Get a Safety Plan from Verak today!" 
        mobileStyles={{background: "#FFF7F1"}}
        desktopStyles={{background: "#FFF7F1"}}
      />
      <FooterSection 
        quoteCtaProps={{label: "CONTACT US"}}
        pageName={pageName}
        mobileStyles={{background: "#FFF"}}
        desktopStyles={{background: "#fff"}}
        handleGetQuoteClick={(e, ctaName="CONTACT US") => handleGetQuoteClick(e, "", ctaName)} 
      />
      <FAQs adLP={false} />
      <Footer />
    </>
  )
}

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        ns: {
          in: ["index", "common", "navbar", "footer"]
        }, 
        language: {eq: "en"}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const SafeGuardBusinessContainer = styled.div`
  .primary-icons > path {
    fill: #2A2B7F;
  }
  .primary-icons > rect {
    fill: #F4E5DC;
  }
`

const HeroSectionContainer= styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 48px;
  margin: 32px 0;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 16px;
    margin: 0;
  }
`

const HeroImgContainer =  styled.div`
  width: min(592px, 100%);
  margin-top: 1rem;
  @media screen and (max-width: 768px) {
    align-self: center;
    margin-top: 0;
  }
  .gatsby-image-wrapper [data-main-image] {
    transition: none !important;
  }
`

export default SafetyPlan